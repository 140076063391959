
































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import divisionsClient from '@/clients/divisionsClient'
import teamsClient from '@/clients/teamsClient'
import SelectInput from '@/elements/SelectInput.vue'
import MultiselectInput from '@/elements/MultiselectInput.vue'
import GroupMessageCoachFilter from '@/components/Communication/GroupMessageCoachFilter.vue'
import Loading from '@/elements/Loading.vue'
import { LeagueDivisionInfo } from '@/GeneratedTypes/ListInfo/LeagueDivisionInfo'
import { DivisionTeamInfo } from '@/GeneratedTypes/ListInfo/DivisionTeamInfo'
import { GroupMessageTypes } from '@/components/Communication/GroupMessageSelector/GroupMessageSelectorData'
import { cloneDeep } from 'lodash'

export interface FilterSelections {
  division: number
  typeProgramId: string
  teams: number[] | null
  coach: string | null
}

export function getEmptyFilterSelection(): FilterSelections {
  return {
    division: 0,
    typeProgramId: '',
    teams: [],
    coach: null,
  }
}

@Component({
  components: {
    SelectInput,
    MultiselectInput,
    GroupMessageCoachFilter,
    Loading,
  },
})
export default class GroupMessageFilter extends Vue {
  @Prop({ type: String, default: GroupMessageTypes.PAGELINK, required: true })
  private readonly groupType!: string
  @Prop({ type: Object, default: getEmptyFilterSelection, required: true })
  private readonly filters!: FilterSelections
  get divisionId(): number {
    return this.filters.division
  }
  set divisionId(n: number) {
    const f = this.filterSelections
    f.division = n
    this.emitFilters(f)
  }

  get teamIds(): number[] | null {
    return this.filters.teams ?? []
  }
  set teamIds(n: number[] | null) {
    const f = this.filterSelections
    f.teams = cloneDeep(n)
    this.emitFilters(f)
  }
  get coach(): string | null {
    return this.filters.coach
  }
  set coach(v: string | null) {
    const f = this.filterSelections
    f.coach = v
    this.emitFilters(f)
  }
  loading = false
  allDivisions: LeagueDivisionInfo[] | null = [] as LeagueDivisionInfo[]
  allTeams = [] as DivisionTeamInfo[]
  emitFilters(filters: FilterSelections): void {
    this.emitter(filters)
  }

  async mounted() {
    await this.loadData()
    const f = this.filterSelections
    this.emitFilters(f)
  }

  async loadData() {
    this.loading = true
    this.$emit('loading', true)
    try {
      const results = await Promise.all([this.retrieveDivisions(), this.retrieveTeams()])
      this.allDivisions = results[0]
      this.allTeams = results[1]
      if (!this.showFilter) {
        this.$emit('unconfiguredLeague')
      }
    } finally {
      this.loading = false
      this.$emit('loading', false)
    }
  }

  get showFilter() {
    return this.allDivisions && this.allDivisions.length > 0 && this.allTeams && this.allTeams.length > 0
  }
  get filterSelections(): FilterSelections {
    const coach = this.isRoster ? this.coach : null
    const filters = getEmptyFilterSelection()
    filters.division = this.divisionId
    filters.typeProgramId = this.programTypeId
    filters.teams = this.teamIds
    filters.coach = coach
    return filters
  }
  emitter(filters: FilterSelections) {
    this.$emit('change', filters)
  }

  get programTypeId(): string {
    const divisions = cloneDeep(this.allDivisions)
    if (!divisions) return ''
    const division = divisions.find((d) => d.divisionID === this.divisionId)
    if (!division || !division.typeProgramID) {
      return '0'
    }
    return division.typeProgramID
  }

  retrieveDivisions() {
    return divisionsClient.retrieveAll(this.upwId)
  }

  retrieveTeams() {
    return teamsClient.retrieveLeagueTeams({ leagueID: this.upwId })
  }

  get teams() {
    let teams =
      this.divisionId === 0 ? this.allTeams : this.allTeams.filter((t) => t.divisionID === this.divisionId)
    return teams.sort((a, b) => ((a.teamName ?? '') > (b.teamName ?? '') ? 1 : -1))
  }

  get upwId() {
    return this.$route.params.id
  }
  get isRoster() {
    return this.groupType === GroupMessageTypes.ROSTER
  }
  @Watch('groupType')
  groupChange() {
    const f = this.filterSelections
    this.emitFilters(f)
  }
}
