
















import { defineComponent, ref, computed } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { GroupMessageTypes } from '@/components/Communication/GroupMessageSelector/GroupMessageSelectorData'

export default defineComponent({
  name: 'GroupMessageSelector',
  components: { RadioGroupInput },
  props: {
    value: { type: String, default: GroupMessageTypes.PAGELINK, required: false },
  },
  setup(props, ctx) {
    const list = ref([
      { value: GroupMessageTypes.PAGELINK, text: 'Team Page Link' },
      { value: GroupMessageTypes.ROSTER, text: 'Team Roster' },
    ])

    function emitValue(val: string) {
      ctx.emit('input', val)
    }

    const descriptiveText = computed((): string => {
      switch (props.value) {
        case GroupMessageTypes.PAGELINK:
          return 'Send a team page link to one or more guardians via email or sms.'
        case GroupMessageTypes.ROSTER:
          return 'Send a link of the roster to one or more coaches via email or sms. Accessing the team roster requires an active coach login. Be sure to invite your coaches first before sending the team roster.'
        default:
          return ''
      }
    })

    return {
      list,
      descriptiveText,
      emitValue,
    }
  },
})
