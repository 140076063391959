































import { Component, Vue, Prop } from 'vue-property-decorator'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
import Count from '@/lib/support/utilities/smsCount.ts'
import MemoInput from '@/elements/MemoInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'

@Component({ components: { MemoInput, HtmlEditor } })
export default class MessageBodyEditor extends Vue {
  @Prop({ type: String, default: '', required: true })
  private readonly value!: string

  @Prop({ type: String, default: MessageChannelTypes.EMAIL, required: true })
  private readonly channel!: string

  @Prop({ type: Boolean, default: false, required: false })
  private readonly readOnly!: boolean

  private messageChannelTypes = MessageChannelTypes
  private maxPages = 3

  get charactersRemaining() {
    return Count(this.value)
  }

  get maxCharacterCount() {
    const c = Count(this.value)

    if (this.value) {
      return c.perMessage * this.maxPages
    }
    return c.perMultiMessage * this.maxPages
  }

  get maxCharactersReached() {
    return (
      this.charactersRemaining.messages > this.maxPages ||
      (this.charactersRemaining.messages === this.maxPages && this.charactersRemaining.remaining === 0)
    )
  }

  get isEmail() {
    return this.channel.toLowerCase() === MessageChannelTypes.EMAIL
  }

  get isSms() {
    return this.channel.toLowerCase() === MessageChannelTypes.SMS
  }

  emitValue(val: string) {
    this.$emit('input', val)
  }
}
