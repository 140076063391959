





































import uuid from 'uuid'
import { defineComponent, ref, watch, PropType } from '@vue/composition-api'
import BaseInputWrapper from '@/elements/BaseInputWrapper.vue'
import vSelect from 'vue-select'
import 'vue-select/src/scss/vue-select.scss'
import { createPopper } from '@popperjs/core'

export default defineComponent({
  name: 'MultiSelectInput',
  props: {
    value: { type: Array as PropType<any[]>, required: false, default: () => [] as string[] },
    itemsList: { type: Array as PropType<Object[]>, required: true },
    label: { type: String, required: false, default: '' },
    placeholder: { type: String, required: false, default: '' },
    required: { type: Boolean, required: false, default: false },
    id: { type: String, required: false, default: '' },
    enabled: { type: Boolean, required: false, default: true },
    textPropertyName: { type: String, required: false, default: '' },
    valuePropertyName: { type: String, required: false, default: '' },
    prependIcon: { type: String, required: false, default: '' },
    appendIcon: { type: String, required: false, default: '' },
    listDirectionUp: { type: Boolean, required: false, default: false },
  },
  components: {
    BaseInputWrapper,
    vSelect,
  },
  setup(props, ctx) {
    const guid = uuid.v4()
    const elementId = props.id || `multiselect-${guid}`

    const selected = ref<Object[]>([])

    function getKeys() {
      return selected.value.map((x) => {
        return Reflect.get(x, props.valuePropertyName)
      })
    }

    function getObjects() {
      const x = props.value.map((x) =>
        props.itemsList.find((y) => Reflect.get(y, props.valuePropertyName) == x)
      )
      return x
    }

    function handleChange() {
      const output = props.valuePropertyName ? getKeys() : selected.value
      ctx.emit('input', output)
      ctx.emit('change', output)
    }

    function withPopper(dropdownList: any, component: any, { width }: { width: any }) {
      dropdownList.style.width = width
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'top',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -1],
            },
          },
          {
            name: 'toggleClass',
            enabled: true,
            phase: 'write',
            fn({ state }) {
              component.$el.classList.toggle('drop-up', state.placement === 'top')
            },
          },
        ],
      })
      return () => popper.destroy()

      return 200
    }

    watch(
      () => props.value,
      () => {
        if (!props.value) return
        selected.value = props.valuePropertyName ? getObjects() : props.value
      },
      { immediate: true }
    )

    return {
      handleChange,
      selected,
      elementId,
      withPopper,
    }
  },
})
