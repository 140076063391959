















import { defineComponent, ref } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'

//These are strings because that is what the API expects
export enum CoachFilters {
  ALL = '0',
  HEAD = '1',
}

export default defineComponent({
  name: 'GroupMessageCoachFilter',
  components: { RadioGroupInput },
  props: {
    value: { type: String, default: CoachFilters.ALL, required: false },
  },
  setup(props, ctx) {
    const list = ref([
      { value: CoachFilters.ALL, text: 'All Coaches' },
      { value: CoachFilters.HEAD, text: 'Head Coach Only' },
    ])

    function emitValue(val: string) {
      ctx.emit('input', val)
    }

    return {
      list,
      emitValue,
    }
  },
})
