















import { defineComponent } from '@vue/composition-api'
import TextInput from '@/elements/TextInput.vue'

export default defineComponent({
  name: 'GroupMessageSubject',
  components: { TextInput },
  props: {
    value: { type: String, default: '', required: true },
    hideSubject: { type: Boolean, default: false, required: true },
  },
  setup(props, ctx) {
    function emitValue(val: string) {
      ctx.emit('input', val)
    }

    return {
      emitValue,
    }
  },
})
