















import { defineComponent, ref } from '@vue/composition-api'
import RadioGroupInput from '@/elements/RadioGroupInput.vue'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'

export default defineComponent({
  name: 'GroupMessageChannel',
  components: { RadioGroupInput },
  props: {
    value: { type: String, default: MessageChannelTypes.EMAIL, required: false },
  },
  setup(props, ctx) {
    const list = ref([
      { value: MessageChannelTypes.EMAIL, text: 'Email' },
      { value: MessageChannelTypes.SMS, text: 'SMS' },
    ])

    function emitValue(val: string) {
      ctx.emit('input', val)
    }

    return {
      list,
      emitValue,
    }
  },
})
