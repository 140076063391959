



















import { Component, Vue, Prop } from 'vue-property-decorator'
import RecipientPreviewList from '@/components/Communication/RecipientListPreview.vue'
import MessageBodyEditor from '@/components/Communication/MessageBodyEditor.vue'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
import { GroupMessageTypes } from '@/components/Communication/GroupMessageSelector/GroupMessageSelectorData'

@Component({
  components: {
    RecipientPreviewList,
    MessageBodyEditor,
  },
})
export default class GroupMessageEditor extends Vue {
  @Prop({ type: String, default: MessageChannelTypes.EMAIL, required: true })
  private readonly channel!: string

  @Prop({ type: String, default: GroupMessageTypes.PAGELINK, required: true })
  private readonly groupType!: string

  @Prop({ type: String, default: MessageChannelTypes.EMAIL, required: true })
  private readonly value!: string

  @Prop({ type: Object, default: getEmptyRecipients, required: true })
  private readonly recipients!: Recipients

  @Prop({ type: Boolean, default: false, required: false })
  private readonly readonly!: Boolean

  emitValue(val: string) {
    this.$emit('input', val)
  }

  get instructions() {
    let buttonText = ''
    if (this.channel === MessageChannelTypes.EMAIL) {
      buttonText = `the participants' first name, the team name and `
    }
    if (this.groupType === GroupMessageTypes.PAGELINK) {
      return `**  The above message will include ${buttonText}a link to the participants' team page.`
    }
    if (this.groupType === GroupMessageTypes.ROSTER) {
      return `** The above message will include ${buttonText} a link to the coach's roster.`
    }
    return ''
  }
}

export interface Recipients {
  groups: string[] | null
  individuals: string[] | null
}

export function getEmptyRecipients() {
  return {
    groups: [] as string[],
    individuals: [] as string[],
  }
}
