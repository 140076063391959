export interface GroupMessageParameters {
  AccountNumber: string
  DivisionID: string //'0' for all
  TeamID: string // '0' for all or comma delimited string for many
  TypeProgramID: string
  CommunicationTypeID: string // 'EMAIL' or 'SMS'
  HeadCoachOnly: string | null // '0' for all coaches; '1' for head coach only
  MessageBody: string
  PreviewOnly: boolean
  SendCommunicationOn: Date | null
  MinutesToAdd: number | null
  SubjectLine: string | null
}

export function getEmptyGroupMessageParameters(): GroupMessageParameters {
  return {
    AccountNumber: '0',
    DivisionID: '0',
    TeamID: '0',
    TypeProgramID: '',
    CommunicationTypeID: 'SMS',
    HeadCoachOnly: null,
    MessageBody: '',
    PreviewOnly: true,
    SendCommunicationOn: null,
    MinutesToAdd: null,
    SubjectLine: null,
  }
}
