































































































































import { Component, Vue, Watch, Ref } from 'vue-property-decorator'
import { Getter } from 'vuex-class'
import * as authStore from '@/store/authorization'
import commClient from '@/clients/communicationsClient'
import Loading from '@/elements/Loading.vue'
import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import SectionHeader from '@/components/SectionHeader.vue'
import GroupMessageSelector from '@/components/Communication/GroupMessageSelector/GroupMessageSelector.vue'
import GroupMessageChannel from '@/components/Communication/GroupMessageChannel/GroupMessageChannel.vue'
import GroupMessageFilters, {
  FilterSelections,
  getEmptyFilterSelection,
} from '@/components/Communication/GroupMessageFilter.vue'
import GroupMessageEditor, { getEmptyRecipients } from '@/components/Communication/GroupMessageEditor.vue'
import GroupMessageSubject from '@/components/Communication/GroupMessageSubject.vue'
import CommunicationModal from '@/components/CommunicationModal.vue'
import InfoModal from '@/components/InfoModal.vue'
import { RecipientTypes } from '@/views/Programs/Communications/ext/RecipientGroups'
import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
import {
  GroupMessageTypes,
  GroupMessageTokens,
} from '@/components/Communication/GroupMessageSelector/GroupMessageSelectorData'
import { GroupMessageParameters, getEmptyGroupMessageParameters } from '@/models/GroupMessageParameters'
import { GroupMessageContact } from '@/models/GroupMessageContact'
import { MessageData, getEmptyMessageData } from '@/lib/support/models/Communication/PreviewMessage'
import {
  rosterEmailBoilerplate,
  rosterSmsBoilerplate,
  teamPageEmailBoilerplate,
  teamPageSmsBoilerplate,
} from '@/lib/support/components/GroupMessage/Boilerplates.ts'
import { mixin as clickaway } from 'vue-clickaway'
import ModalDateTimePicker from '@/components/ModalDateTimePicker.vue'
import dayjs from 'dayjs'
import communicationsClient from '@/clients/communicationsClient'

@Component({
  components: {
    Loading,
    SectionHeader,
    GroupMessageSelector,
    GroupMessageChannel,
    GroupMessageEditor,
    GroupMessageFilters,
    CommunicationModal,
    InfoModal,
    VeeValidateForm,
    ModalDateTimePicker,
    GroupMessageSubject,
  },
  mixins: [clickaway],
})
export default class GroupMessage extends Vue {
  @Getter(authStore.getterNames.firstAccountNumber, { namespace: authStore.namespace })
  private accountNumber!: string

  channel = ''
  groupMessageType = ''
  hideAll = false
  showPreview = false
  showEmailConfirmationModal = false
  messageBody = ''
  isPreviewing = false
  isSending = false
  isSendingEmailFromPreview = false
  isFiltersLoading = false
  recipients = getEmptyRecipients()
  previewData = getEmptyMessageData()
  filters: FilterSelections = getEmptyFilterSelection()
  division = 0
  teams: number[] = []
  coach = '0'
  subject = ''
  hideSubjectLine = false
  private showSendDrop = false
  private showScheduleSendModal = false
  private sendCommunicationOn: Date | null = null
  @Ref('form') readonly form!: HTMLFormElement
  async mounted() {
    if (this.messageID) {
      const message = await communicationsClient.getQueuedGroupMessage(parseInt(this.messageID))
      this.channel = message?.messageType === 'email' ? MessageChannelTypes.EMAIL : MessageChannelTypes.SMS
      this.groupMessageType =
        message?.groupMessageType === 'teamroster' ? GroupMessageTypes.ROSTER : GroupMessageTypes.PAGELINK
      const divisionID = message?.recipientCriteria?.divisionID ?? '0'
      //this.subject = message.subject (need to add)
      this.filters = {
        division: parseInt(divisionID),
        typeProgramId: message?.recipientCriteria?.typeProgramID ?? '',
        teams: message?.recipientCriteria?.teams ?? [],
        coach: message?.recipientCriteria?.headCoachOnly === true ? '1' : '0',
      }
    } else {
      this.channel = MessageChannelTypes.EMAIL
      this.groupMessageType = GroupMessageTypes.PAGELINK
      this.subject =
        this.groupMessageType === 'teamroster'
          ? 'Upward Team Roster is Available!'
          : 'Upward Team Assignments are Complete!'
    }
  }

  async sendMessageFromModal() {
    this.isSendingEmailFromPreview = true
    try {
      await this.callAPI('SEND')
      this.showEmailConfirmationModal = true
    } finally {
      this.isSendingEmailFromPreview = false
      this.showPreview = false
    }
  }

  async preview() {
    const formIsValid = await this.form.reportValidity()
    if (!formIsValid) return
    this.isPreviewing = true
    try {
      const preview = await this.callAPI('PREVIEW')
      this.previewData = this.composePreviewMessageData(preview)
      this.showPreview = true
    } finally {
      this.isPreviewing = false
    }
  }

  async send() {
    this.isSending = true
    try {
      await this.callAPI('SEND')
      this.showEmailConfirmationModal = true
    } finally {
      this.isSending = false
    }
  }

  async callAPI(action: 'SEND' | 'PREVIEW') {
    const params = this.composeGroupMessageParameters()
    if (action === 'SEND') params.PreviewOnly = false
    if (this.groupMessageType === GroupMessageTypes.PAGELINK) {
      return await commClient.sendTeamPageLinkGroupMessage(this.upwId, params)
    }

    if (this.groupMessageType === GroupMessageTypes.ROSTER) {
      return await commClient.sendTeamRosterGroupMessage(this.upwId, params)
    }
    return null
  }

  composeGroupMessageParameters(): GroupMessageParameters {
    const p = getEmptyGroupMessageParameters()
    p.AccountNumber = this.accountNumber
    p.CommunicationTypeID = this.channel.toUpperCase()
    p.TypeProgramID = this.filters.typeProgramId
    p.DivisionID = this.filters.division.toString()
    p.MessageBody = this.messageBody
    p.SendCommunicationOn = this.sendCommunicationOn
    p.MinutesToAdd = this.minutesToAdd
    if (this.groupMessageType === GroupMessageTypes.ROSTER) {
      p.HeadCoachOnly = this.filters.coach ?? '0'
    }
    if (this.filters.teams) {
      p.TeamID = this.filters.teams.join(',')
    }
    p.SubjectLine = this.subject
    return p
  }

  composePreviewMessageData(preview: GroupMessageContact[] | null): MessageData {
    if (!preview) throw Error('no preview data available')
    const contacts = this.conactList(preview)
    const m = getEmptyMessageData()
    m.messageBody = `${this.messageBody}<br/>Team Name:</br>Participant Name:</br>${this.groupMessageToken}`
    m.messageType = this.channel
    m.individualRecipients = contacts
    m.recipientCount = contacts.length
    m.subject = this.subject
    return m
  }

  get groupMessageToken() {
    return GroupMessageTokens.get(this.groupMessageType)
  }

  conactList(preview: GroupMessageContact[]) {
    return preview
      .filter((c) => c.Contact != '')
      .map((c) => c.Contact.split(','))
      .flat()
  }

  get actionText() {
    if (this.channel === MessageChannelTypes.EMAIL) return 'Send Email'
    return 'Send Text'
  }

  get upwId() {
    return this.$route.params.id
  }
  get messageID() {
    return this.$route.params.messageId
  }
  filtersLoading(val: boolean) {
    this.isFiltersLoading = val
  }

  setFilters(filters: FilterSelections) {
    this.filters = filters
  }

  viewScheduled() {
    this.$nextTick(() => {
      this.$router.push(`/programs/league/${this.$route.params.id}/communications/scheduled`)
    })
  }

  setMessageBodyBoilerplate() {
    if (this.groupMessageType === GroupMessageTypes.PAGELINK) {
      if (this.channel === MessageChannelTypes.EMAIL) this.messageBody = teamPageEmailBoilerplate
      if (this.channel === MessageChannelTypes.SMS) this.messageBody = teamPageSmsBoilerplate
    }
    if (this.groupMessageType === GroupMessageTypes.ROSTER) {
      if (this.channel === MessageChannelTypes.EMAIL) this.messageBody = rosterEmailBoilerplate
      if (this.channel === MessageChannelTypes.SMS) this.messageBody = rosterSmsBoilerplate
    }
  }

  private toggleSendDrop() {
    this.showSendDrop = !this.showSendDrop
  }
  private sendDropAway() {
    this.showSendDrop = false
  }
  private async scheduleSend(validate: () => Promise<boolean>) {
    var valid = await validate()
    this.showScheduleSendModal = valid
  }
  private get minutesToAdd(): number | null {
    if (!this.sendCommunicationOn) return null
    const now = dayjs(new Date(Date.now()))
    const sendOn = dayjs(this.sendCommunicationOn)
    return sendOn.diff(now, 'minute', false)
  }
  private get sendCommunicationOnFormatted(): string {
    return (
      this.sendCommunicationOn?.toLocaleTimeString([], {
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: '2-digit',
      }) || ''
    )
  }

  hideSubject() {
    if (this.channel === MessageChannelTypes.SMS) {
      this.hideSubjectLine = true
      this.subject = ''
    } else {
      this.hideSubjectLine = false
      this.subject =
        this.groupMessageType === 'teamroster'
          ? 'Upward Team Roster is Available!'
          : 'Upward Team Assignments are Complete!'
    }
  }

  @Watch('groupMessageType')
  groupMessageTypeChanged() {
    if (this.groupMessageType === GroupMessageTypes.PAGELINK) {
      this.recipients.groups = [RecipientTypes.PARENTS]
      this.subject = 'Upward Team Assignments are Complete!'
    }
    if (this.groupMessageType === GroupMessageTypes.ROSTER) {
      this.recipients.groups = [RecipientTypes.COACHES]
      this.subject = 'Upward Team Roster is Available!'
    }
    this.setMessageBodyBoilerplate()
  }

  @Watch('channel')
  channelChanged() {
    this.setMessageBodyBoilerplate()
    this.hideSubject()
  }
}
